import React from 'react'
import { useAPIContext } from '../context/api'
import { ExternalMetadataAPI } from '../../../constructs/external-metadata/interface'
import { MediaData } from '../../../types/media-data'
import { VALID_IG_LINK, VALID_TIKTOK_LINK } from '../constants/media-platform'
import sanitizeUrl from '../utils/url-sanitizer'

export const useFetchMedia = () => {
  const { callEndpoint } = useAPIContext()

  const fetchExternalMetadata = React.useCallback(
    async (urls: string[]): Promise<MediaData[]> => {
      const validUrls = urls.filter(url => VALID_IG_LINK.test(url) || VALID_TIKTOK_LINK.test(url))
      if (validUrls.length === 0) return []

      const sanitizedUrls = validUrls.map(url => {
        if (VALID_IG_LINK.test(url)) return sanitizeUrl(url)
        return url
      })

      try {
        const response = await callEndpoint<ExternalMetadataAPI, 'get-metadata'>('external-metadata', '', 'POST', {
          externalUrls: sanitizedUrls,
        })

        return response.map(data => ({
          mediaUrl: data.mediaUrl,
          thumbnail: data.thumbnail || '',
          caption: data.caption || '',
        }))
      } catch (error) {
        console.error('Error fetching url data:', error)
        return []
      }
    },
    [callEndpoint],
  )

  return { fetchExternalMetadata }
}
