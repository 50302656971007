import { typography } from './typography'

export const CARD_CONFIG = {
  standard: {
    size: {
      width: '100%',
      flex: 1,
      minWidth: '200px',
      maxWidth: 'auto',
    },
    rowHeight: '20px',
    gaugeSize: 7,
    title: {
      videoImpressions: 'Visualizaciones del video',
      successfulViews: 'Visualizaciones exitosas',
      videoClicks: 'Clicks en el video',
    },
    fontSize: {
      title: typography.body.sm.fontSize,
      paragraph: typography.heading.lg.fontSize,
      gaugeValue: '1em,',
    },
  },
  performance: {
    size: {
      width: '100%',
      flex: 1,
      minWidth: '200px',
      maxWidth: 'auto',
    },
    rowHeight: '30px',
    gaugeSize: 9,
    title: {
      videoImpressions: 'Total visualizaciones',
      successfulViews: 'Total visualizaciones exitosas',
      videoClicks: 'Total clicks',
    },
    fontSize: {
      title: typography.body.md.fontSize,
      paragraph: typography.heading.xl.fontSize,
      gaugeValue: '1.5em',
    },
  },
} as const

type CardConfig = typeof CARD_CONFIG
export type ConfigType = CardConfig[keyof CardConfig]
