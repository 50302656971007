import React from 'react'
import dayjs from 'dayjs'
import { CarouselAPI } from '../../../../constructs/carousel/interface'
import { useAPIContext } from '../../context/api'

export const useFetchCarouselData = ({ widgetId }: { widgetId: string }) => {
  const { callEndpoint } = useAPIContext()

  const fetchCarouselData = React.useCallback(
    async (selectedDate: dayjs.Dayjs) => {
      try {
        const carousel = await callEndpoint<CarouselAPI, 'get'>(
          'carousel',
          `/${widgetId}/${selectedDate.format('YYYY-MM-DD')}`,
          'GET',
        )
        return carousel.content || []
      } catch (err) {
        return []
      }
    },
    [widgetId, callEndpoint],
  )

  return { fetchCarouselData }
}
