import React from 'react'
import { useAPIContext } from '../../context/api'
import { VideoAPI } from '../../../../constructs/video-processing/interface'
import { PosterAPI } from '../../../../constructs/poster-processing/interface'
import { v4 as uuidv4 } from 'uuid'

export const useUploadMedia = () => {
  const { callEndpoint } = useAPIContext()

  const uploadPoster = React.useCallback(
    async (fileType: 'image', fileExtension: string, file: File) => {
      try {
        if (!file || !file.type) {
          throw new Error('Archivo no válido o tipo de archivo no especificado.')
        }

        const uuid = uuidv4() // folder and fileName

        const path = `/poster/${uuid}/${uuid}`

        const response = await callEndpoint<PosterAPI, 'post'>('optimized', path, 'POST', {
          fileType,
          fileExtension,
        })

        const { uploadUrl, fileKey } = response

        const s3Response = await fetch(uploadUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        })

        if (!s3Response.ok) {
          const errorText = await s3Response.text()
          throw new Error(`Error subiendo archivo a S3: ${s3Response.status} - ${errorText}`)
        }

        console.log('Uploaded poster:', { s3Response, fileKey, uuid, path })

        return {
          fileKey,
          uuid,
          path: path,
        }
      } catch (error) {
        console.error('Hook | Error al subir el poster:', error)
        throw error
      }
    },
    [callEndpoint],
  )

  const uploadVideo = React.useCallback(
    async (fileType: 'video', fileExtension: string, file: File) => {
      try {
        if (!file || !file.type) {
          throw new Error('Archivo no válido o tipo de archivo no especificado.')
        }

        const uuid = uuidv4()

        const path = `/${uuid}/video-mp4/video.mp4`

        const response = await callEndpoint<VideoAPI, 'post'>('upload', path, 'POST', {
          fileType,
          fileExtension,
        })

        const { uploadUrl, fileKey } = response

        const s3Response = await fetch(uploadUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        })

        if (!s3Response.ok) {
          const errorText = await s3Response.text()
          throw new Error(`Error subiendo archivo a S3: ${s3Response.status} - ${errorText}`)
        }

        return {
          fileKey,
          uuid,
          path: path,
          videoId: uuid,
        }
      } catch (error) {
        console.error('Hook | Error al subir el video:', error)
        throw error
      }
    },
    [callEndpoint],
  )

  return { uploadVideo, uploadPoster }
}
