import React from 'react'
import { CalendarAPI } from '../../../../constructs/calendar/interface'
import { useAPIContext } from '../../context/api'
import { CalendarContent } from '../../../../types/calendar'

export const useFetchCarouselDates = ({ widgetId }: { widgetId: string }) => {
  const { callEndpoint } = useAPIContext()
  const [submittedDates, setSubmittedDates] = React.useState<string[]>([])

  const fetchSubmittedDates = React.useCallback(async () => {
    try {
      const calendar = await callEndpoint<CalendarAPI, 'get'>('calendar', `/${widgetId}`, 'GET')

      const calendarDates = (calendar || []).map(
        (calendarDateItem: CalendarContent) => calendarDateItem.date,
      )

      setSubmittedDates(calendarDates)
      return calendarDates
    } catch (err: any) {
      console.error('Error fetching calendar dates:', err)
      throw err
    }
  }, [widgetId, callEndpoint])

  return { fetchSubmittedDates, submittedDates }
}
