import React from 'react'
import { CarouselAPI } from '../../../constructs/carousel/interface'
import { useAPIContext } from '../context/api'

export const useFetchVideos = () => {
  const { callEndpoint } = useAPIContext()

  const fetchVideos = React.useCallback(async () => {
    try {
      const response = await callEndpoint<CarouselAPI, 'list-videos'>('carousel', `/videos`, 'GET')
      return response || []
    } catch (err) {
      console.error('API Error:', err)
      throw err
    }
  }, [callEndpoint])

  return { fetchVideos }
}
