import React from 'react'
import Hls from 'hls.js'

interface HLSPlayerProps extends React.VideoHTMLAttributes<HTMLVideoElement> {}

export const HLSPlayer: React.FC<HLSPlayerProps> = ({ src, ...props }) => {
  const elRef = React.useRef<HTMLVideoElement>(null)
  const hlsRef = React.useRef<Hls | null>(null)

  React.useEffect(() => {
    hlsRef.current = new Hls({
      debug: false,
      progressive: true,
      maxBufferLength: 5,
      enableWorker: true,
    })

    return () => {
      hlsRef.current?.destroy()
    }
  }, [])

  React.useEffect(() => {
    if (!src || !src.endsWith('.m3u8')) return

    const localVideoRef = elRef.current
    const hls = hlsRef.current

    if (localVideoRef && Hls.isSupported()) {
      hls?.loadSource(src)
      hls?.attachMedia(localVideoRef)
      hls?.on(Hls.Events.ERROR, err => {
        console.log(err)
      })

      return () => {
        hls?.detachMedia()
      }
    }
  }, [src])

  return <video ref={elRef} {...props}></video>
}
