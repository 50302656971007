import React from 'react'
import { Image, Skeleton } from 'antd'
import { PosterDisplayState } from '../../../types/card'

const PREVIEW_WIDTH = 72
const PREVIEW_HEIGHT = 128

interface PosterProps {
  image: string | undefined
  state: PosterDisplayState
}
export const Poster: React.FC<PosterProps> = React.memo(
  ({ image, state }) => {
    return (
      <div
        style={{
          width: PREVIEW_WIDTH,
          height: PREVIEW_HEIGHT,
          backgroundColor: '#F8F7F7',
          borderRadius: 3,
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {state === 'processing' ? (
          <div
            style={{
              width: PREVIEW_WIDTH,
              height: PREVIEW_HEIGHT,
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Skeleton.Image
              style={{
                width: PREVIEW_WIDTH,
                height: PREVIEW_HEIGHT,
                borderRadius: 3,
              }}
            />
            <span
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: '#595959',
                fontSize: 10,
                fontWeight: 600,
                background: 'rgba(255, 255, 255, 0.9)',
                padding: '4px 8px',
                borderRadius: 3,
                zIndex: 1,
              }}
            >
              En proceso
            </span>
          </div>
        ) : state === 'empty' ? (
          <div
            style={{
              width: PREVIEW_WIDTH,
              height: PREVIEW_HEIGHT,
              backgroundColor: '#F8F7F7',
              borderRadius: 3,
            }}
          />
        ) : state === 'preview' && image ? (
          <Image
            src={image}
            alt='preview'
            width='100%'
            height='100%'
            style={{ objectFit: 'cover', borderRadius: 3 }}
            preview={true}
          />
        ) : null}
      </div>
    )
  },
  (prevProps, nextProps) => prevProps.image === nextProps.image && prevProps.state === nextProps.state,
)
