import React from 'react'
import { message } from 'antd'
import { CarouselAPI } from '../../../../constructs/carousel/interface'
import { useAPIContext } from '../../context/api'

export const useSaveCarouselContent = ({ widgetId }: { widgetId: string }) => {
  const { callEndpoint } = useAPIContext()
  const [saving, setSaving] = React.useState(false)

  const saveCarouselContent = React.useCallback(
    async (date: string, carouselData: any) => {
      setSaving(true)
      try {
        const res = await callEndpoint<CarouselAPI, 'save'>('carousel', `/${widgetId}/${date}`, 'POST', carouselData)

        if (!res) throw new Error('Respuesta del servidor inesperada')

        return true
      } catch (error: any) {
        message.error('Hubo un error al enviar el contenido. Intenta nuevamente')
        return false
      } finally {
        setSaving(false)
      }
    },
    [widgetId, callEndpoint],
  )

  return { saveCarouselContent, saving }
}
