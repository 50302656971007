import React from 'react'
import { Card, Col, Row, Skeleton, Tooltip, Typography } from 'antd'
import { MousePointerClick } from 'lucide-react'

import { ConfigType } from '../../../theme/card'
import { tokens } from '../../../theme/tokens'
import { typography } from '../../../theme/typography'
import { numberFormatter, getMetricTooltipContent, calculateRatio } from '../../../utils/metrics-utils'
import { useDashboardContext } from '../../../context/dashboard'

const { Text } = Typography
interface VideoClicksProps {
  video_impressions: number
  cta_click: number
  config: ConfigType
}

const VideoClicks: React.FC<VideoClicksProps> = ({ video_impressions, cta_click, config }) => {
  const { isLoading } = useDashboardContext()

  const ratio = calculateRatio(cta_click, video_impressions)
  const percentage = (ratio * 100).toFixed(2)

  return (
    <Tooltip title={getMetricTooltipContent({ metricType: 'videoClicks', value: cta_click })} placement='topLeft'>
      <Card
        style={{
          ...config.size,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          padding: '7px',
        }}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <Row
          style={{
            width: '100%',
            height: config.rowHeight,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Col>
            <MousePointerClick style={{ width: '14px' }} />
          </Col>
        </Row>
        <Row
          style={{
            width: '100%',
            height: '55px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {isLoading ? (
            <Skeleton.Input active={true} size='small' />
          ) : (
            <Col style={{ display: 'flex', justifyContent: 'center' }}>
              <Row
                style={{
                  width: '100%',
                  height: '60px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Text style={{ fontSize: config.fontSize.paragraph, fontWeight: 600 }}>
                  {numberFormatter(cta_click)}
                </Text>
              </Row>
            </Col>
          )}
          {isLoading ? (
            <Skeleton.Input active={true} size='small' />
          ) : (
            <Col
              style={{
                backgroundColor: tokens.colors.primary.neutral[50],
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingLeft: '8px',
                marginRight: '10px',
              }}
            >
              <Text
                style={{
                  fontSize: config.fontSize.paragraph,
                  fontWeight: 600,
                  lineHeight: typography.heading.lg.lineHeight,
                }}
              >
                {percentage}%
              </Text>
            </Col>
          )}
        </Row>
        <Row
          style={{
            width: '100%',
            height: config.rowHeight,
            flexGrow: 1,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
        >
          <Text style={{ fontSize: config.fontSize.title }}>{config.title.videoClicks}</Text>
          <Text
            style={{
              fontSize: config.fontSize.title,
              color: '#5E5E5E',
              fontWeight: 600,
              marginRight: '50px',
            }}
          >
            CTR
          </Text>
        </Row>
      </Card>
    </Tooltip>
  )
}

export default VideoClicks
