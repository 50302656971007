import React from 'react'
import { Col, Space, Row, Typography } from 'antd'

import { Share } from 'lucide-react'

import { SecondaryButton } from '../ui/secondary-button'
import { DateRangePicker } from './custom-calendar'

import { useDashboardContext } from '../../context/dashboard'

const { Text } = Typography

const DashboardNavbar: React.FC = () => {
  const { activeDateRangeText } = useDashboardContext()

  const getDateRangeText = () => {
    switch (activeDateRangeText) {
      case 'Última semana':
        return 'la última semana'
      case 'Último mes':
        return 'el último mes'
      case 'Último año':
        return 'el último año'
      case 'Últimos 3 meses':
        return 'los últimos 3 meses'
      default:
        return `${activeDateRangeText.toLowerCase()}`
    }
  }

  return (
    <Row justify='space-between' align='middle'>
      <Col>
        <Text style={{ fontSize: '16px', fontWeight: 600 }}>Tus contenidos para {getDateRangeText()}</Text>
      </Col>
      <Col>
        <Space size='large'>
          <DateRangePicker />
          <SecondaryButton icon={<Share width={12} />} style={{ borderColor: 'rgba(138, 148, 166, 1)' }}>
            Compartir
          </SecondaryButton>
        </Space>
      </Col>
    </Row>
  )
}

export default DashboardNavbar
