import dayjs from 'dayjs'
import { SortState } from '../../types/sort'
import { VideoWithAnalytics } from '../../../types/videos'

export const sortMetricsByConfig = (videos: VideoWithAnalytics[], sortConfig: SortState): VideoWithAnalytics[] => {
  return [...videos].sort((a, b) => {
    const sortDirectionFactor = sortConfig.direction === 'asc' ? 1 : -1

    if (sortConfig.type === 'date') {
      return sortDirectionFactor * (dayjs(a.startDate).unix() - dayjs(b.startDate).unix())
    }

    return sortDirectionFactor * (a[sortConfig.type] - b[sortConfig.type])
  })
}
