import React, { useState, useEffect } from 'react'
import { useAPIContext } from '../context/api'
import { InstagramAPI } from '../../../constructs/instagram/interface'

// ✅ Definimos la interfaz `InstagramReel`
export interface InstagramReel {
  id: string
  url: string
  displayUrl: string
  caption: string
  timestamp: string
}

export const useFetchInstagramReels = () => {
  const { callEndpoint } = useAPIContext()

  const fetchInstagramReels = React.useCallback(
    async (username: string) => {
      try {
        const response = await callEndpoint<InstagramAPI, 'get-reels'>('instagram', `/${username}`, 'GET')
        console.log(response)
        return response
      } catch (error) {
        console.error('❌ Error obteniendo reels de Instagram:', error)
        return []
      }
    },
    [callEndpoint],
  )

  return { fetchInstagramReels }
}
