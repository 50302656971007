import React from 'react'
import { Button, Col, Pagination, Row, Space, Typography, Dropdown, ConfigProvider } from 'antd'
import type { MenuProps } from 'antd'
import { Plus, ArrowDownAZ, ArrowUpAZ, Filter } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'

import { useDashboardContext, ITEMS_PER_PAGE } from '../context/dashboard'
import { SortState, SortType } from '../../types/sort'
import { SecondaryButton } from '../components/ui/secondary-button'

import DashboardNavbar from '../components/dashboard/navbar'
import MetricsContent from '../components/dashboard/metrics/metrics-container'

const { Title } = Typography

interface DashboardPageProps {
  userInfo?: any
  onNavigateToContent: () => void
}

export const SortButton = () => {
  const { sortConfig, setSortConfig } = useDashboardContext()

  const handleMenuClick = (type: SortType) => {
    setSortConfig((prev: SortState) => ({
      type,
      direction: prev.type === type && prev.direction === 'asc' ? 'desc' : 'asc',
    }))
  }

  const items: MenuProps['items'] = [
    {
      key: 'date',
      label: 'Ordenar por fecha',
      icon:
        sortConfig.type === 'date' ? (
          sortConfig.direction === 'asc' ? (
            <ArrowUpAZ size={16} />
          ) : (
            <ArrowDownAZ size={16} />
          )
        ) : null,
      onClick: () => handleMenuClick('date'),
    },
    {
      key: 'impressions',
      label: 'Ordenar por visualizaciones',
      icon:
        sortConfig.type === 'impressions' ? (
          sortConfig.direction === 'asc' ? (
            <ArrowUpAZ size={16} />
          ) : (
            <ArrowDownAZ size={16} />
          )
        ) : null,
      onClick: () => handleMenuClick('impressions'),
    },
  ]

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <SecondaryButton icon={<Filter width={12} />} style={{ borderColor: 'rgba(138, 148, 166, 1)' }}>
        {`Ordenar por ${sortConfig.type === 'date' ? 'fecha' : 'visualizaciones'} ${
          sortConfig.direction === 'asc' ? '↑' : '↓'
        }`}
      </SecondaryButton>
    </Dropdown>
  )
}

export const DashboardPage: React.FC<DashboardPageProps> = ({ userInfo, onNavigateToContent }) => {
  const { performanceMetrics, currentPage, setCurrentPage, displayedMetrics } = useDashboardContext()

  return (
    <Space
      direction='vertical'
      size='large'
      style={{
        width: '100%',
        padding: '16px',
        paddingLeft: '0px',
      }}
    >
      <Row justify='space-between' align='middle'>
        <Col>
          <Title style={{ fontWeight: 500 }}>
            Hola, <span style={{ fontWeight: 700 }}>{userInfo?.['custom:clientId']}</span>
          </Title>
        </Col>
        <Col>
          <Button
            style={{
              width: '200px',
              height: '45px',
              fontSize: '14px',
              fontWeight: 600,
              color: 'rgba(255, 255, 255, 1)',
            }}
            icon={<Plus width={15} />}
            onClick={onNavigateToContent}
          >
            Cargar Contenido
          </Button>
        </Col>
      </Row>

      <DashboardNavbar />
      <Row justify='center'>
        <Col lg={16}>
          <MetricsContent data={performanceMetrics.summary} />
        </Col>
      </Row>

      <Row justify='end'>
        <Col>
          <SortButton />
        </Col>
      </Row>

      <Row gutter={16} justify={'center'}>
        <Col lg={16} style={{ marginBottom: '20px' }}>
          <ConfigProvider>
            <AnimatePresence mode='sync'>
              {displayedMetrics.videos &&
                displayedMetrics.videos.map((data, index) => (
                  <motion.div
                    key={data.video_source}
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{
                      duration: 0.6,
                      delay: index * 0.1, // efecto de cascada
                    }}
                  >
                    <MetricsContent data={data} />
                  </motion.div>
                ))}
            </AnimatePresence>
          </ConfigProvider>

          <Pagination
            current={currentPage}
            total={performanceMetrics.videos.length}
            pageSize={ITEMS_PER_PAGE}
            onChange={setCurrentPage}
            style={{ display: 'flex', justifyContent: 'center' }}
          />
        </Col>
      </Row>
    </Space>
  )
}
export default DashboardPage
