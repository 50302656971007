import React from 'react'
import { Modal, Card, Typography, Divider } from 'antd'

const { Paragraph, Link } = Typography

export const HelpModal: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => (
  <Modal open={open} onCancel={onClose} footer={null} width={600} title='Ayuda - Content Management'>
    <Card style={{ textAlign: 'left', width: '100%' }}>
      <Typography.Title level={3} style={{ marginTop: 0, marginBottom: 0 }}>
        👋 Bienvenido al content management
      </Typography.Title>
      <Paragraph>Cargá los contenidos de tus publicaciones siguiendo estos pasos:</Paragraph>
      <Card
        style={{
          border: 'none',
          backgroundColor: '#F9FAFF',
          borderRadius: '8px',
        }}
      >
        <Paragraph>1. Selecciona la fecha en la que quieras cargar el contenido del widget</Paragraph>
        <Paragraph>2. Copia y pega el link de tu video</Paragraph>
        <Paragraph>3. Agrega un título</Paragraph>
        <Paragraph>4. Si queres, podes mover las cards para elegir el orden de los videos</Paragraph>
        <Paragraph>
          5. Hace click en <strong>enviar</strong> y listo! tus contenidos quedan programados.
        </Paragraph>
      </Card>
      <Divider />
      <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
        Si tenés dudas o algún problema, envianos un email a:
        <Paragraph style={{ marginBottom: 0 }}>
          <Link href='mailto:soporte@drupi.io' style={{ fontSize: '18px' }}>
            soporte@drupi.io
          </Link>
        </Paragraph>
      </Typography.Title>
    </Card>
  </Modal>
)
