const MEDIA_DOMAIN_VIDEO = process.env.REACT_APP_MEDIA_DOMAIN
const MEDIA_DOMAIN_POSTER = process.env.REACT_APP_MEDIA_DOMAIN_POSTER

export const getMediaDomainVideo = (fileKey: string, fileType: 'video' | 'image'): string => {
  const parts = fileKey.split('/')
  const clientId = parts[1]
  const videoId = parts[2]
  if (fileType === 'video') {
    return `${MEDIA_DOMAIN_VIDEO}/${clientId}/${videoId}.mp4`
  }

  const imageId = parts[4]

  return `${MEDIA_DOMAIN_VIDEO}/${clientId}/${videoId}/image-jpeg/${imageId}`
}

export const getMediaDomainPoster = (fileKey: string): string => {
  const cleanedKey = fileKey.replace(/^optimized\//, '')
  return `${MEDIA_DOMAIN_POSTER}/${cleanedKey}`
}
