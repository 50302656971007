import React from 'react'
import dayjs from 'dayjs'
import { AnalyticsAPI } from '../../../constructs/analytics/interface'
import { useAPIContext } from '../context/api'

export const useFetchAnalytics = ({ widgetId }: { widgetId: string }) => {
  const { callEndpoint } = useAPIContext()

  const fetchAnalytics = React.useCallback(
    async (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
      try {
        const response = await callEndpoint<AnalyticsAPI, 'get'>(
          'analytics',
          `/${widgetId}/${startDate.format('YYYYMMDD')}/${endDate.format('YYYYMMDD')}`,
          'GET',
        )
        return response || []
      } catch (err: any) {
        console.error('API Error:', err)
        throw err
      }
    },
    [widgetId, callEndpoint],
  )

  return { fetchAnalytics }
}
