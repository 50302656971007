import React from 'react'

import VideoImpression from './video-impression'
import VideoClicks from './video-clicks'
import VideoCard from '../video-card'

import { CARD_CONFIG } from '../../../theme/card'
import { AnalyticsData, VideoWithAnalytics } from '../../../../../types/videos'

const MetricsContent: React.FC<{ data: AnalyticsData | VideoWithAnalytics }> = ({ data }) => {
  const config = 'posterUrl' in data ? CARD_CONFIG.standard : CARD_CONFIG.performance

  let containerStyles = {
    backgroundColor: '#ffffff',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '0',
    paddingRight: '0',
    marginBottom: '0',
    borderRadius: '16px',
    gap: '15px',
    height: '145px',
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  }

  if ('posterUrl' in data) {
    containerStyles = {
      ...containerStyles,
      backgroundColor: '#F6F7F9',
      paddingLeft: '10px',
      paddingRight: '10px',
      marginBottom: '13px',
      height: 'auto',
    }
  }

  return (
    <div style={containerStyles}>
      {'posterUrl' in data && (
        <VideoCard
          videoData={{
            videoUrl: data.videoUrl,
            previewUrl: data.previewUrl,
            posterUrl: data.posterUrl,
            caption: data.caption,
            startDate: data.startDate,
          }}
          size={{ width: 108, height: 108 }}
        />
      )}
      <VideoImpression impressions={data.impressions} config={config} />
      <VideoClicks video_impressions={data.impressions} cta_click={data.cta_click} config={config} />
    </div>
  )
}

export default MetricsContent
