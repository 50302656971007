import React, { useState, useCallback, useEffect } from 'react';
import { Modal, List, Button, Typography, Pagination, Input, Image, Tabs, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useFetchVideos } from '../../hooks/list-videos';

import { VideoMetadata } from '../../../../types/videos';

import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { InstagramReel, useFetchInstagramReels } from '../../hooks/useFetchInstagramReels';

dayjs.extend(LocalizedFormat);

interface ReuseVideosModalProps {
  visible: boolean;
  onClose: () => void;
  onSelect: (video: VideoMetadata | InstagramReel) => void;
}

export const ReuseVideosModal: React.FC<ReuseVideosModalProps> = ({ visible, onClose, onSelect }) => {
  const [activeTab, setActiveTab] = useState<string>('history');
  const [reels, setReels] = useState<InstagramReel[]>([]); 

  // Videos Históricos
  const { fetchVideos } = useFetchVideos();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [videos, setVideos] = useState<VideoMetadata[]>([]);
  const [loadingVideos, setLoadingVideos] = useState(true);
  const pageSize = 6;

  // Reels de Instagram
  const { fetchInstagramReels } = useFetchInstagramReels(); // ✅ Modificación
  const [currentReelsPage, setCurrentReelsPage] = useState(1);
  const reelsPageSize = 6;
  const [loadingReels, setLoadingReels] = useState(true); // ✅ Estado de carga para los reels

  const getVideos = useCallback(async () => {
    setLoadingVideos(true);
    try {
      const videos = await fetchVideos();
      setVideos(videos);
    } catch (err) {
      console.error('Edit Form API Error:', err);
    } finally {
      setLoadingVideos(false);
    }
  }, [fetchVideos]);

  useEffect(() => {
    if (visible && activeTab === 'history') {
      getVideos();
    }
  }, [getVideos, visible, activeTab]);

  useEffect(() => {
    const getReels = async () => {
      setLoadingReels(true); // ✅ Inicia el loading antes de la petición
      try {
        const res = await fetchInstagramReels("observadoruy");
        if (res) {
          setReels(res);
        }
      } catch (error) {
        console.error('❌ Error obteniendo reels:', error);
      } finally {
        setLoadingReels(false); // ✅ Detiene el loading cuando termina la petición
      }
    };
  
    if (visible && activeTab === 'instagram-reels') {
      getReels();
    }
  }, [fetchInstagramReels, visible, activeTab]);

  const filteredVideos = videos.filter(video =>
    !!(video.sk.toLowerCase().includes(searchTerm.toLowerCase()) ||
      video.caption?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      video.date?.includes(searchTerm))
  );

  const startIndex = (currentPage - 1) * pageSize;
  const currentVideos = filteredVideos.slice(startIndex, startIndex + pageSize);

  return (
    <Modal title="Historial y Reels" open={visible} onCancel={onClose} footer={null} width={1000}>
      <Tabs
        activeKey={activeTab}
        onChange={setActiveTab}
        items={[
          {
            key: 'history',
            label: 'Videos Históricos',
            children: (
              <>
                <Input
                  placeholder="Buscar en el historial..."
                  allowClear
                  onChange={e => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(1);
                  }}
                  style={{ width: 200, marginBottom: '16px' }}
                  suffix={<SearchOutlined style={{ color: '#000' }} />}
                />
                <List
                  grid={{ gutter: 16, column: 2 }}
                  dataSource={currentVideos}
                  loading={loadingVideos}
                  renderItem={video => (
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '12px',
                          border: '1px solid #e8e8e8',
                          borderRadius: '8px',
                          position: 'relative',
                          height: '180px',
                        }}
                      >
                        <div style={{ width: '120px', height: '100%' }}>
                          <Image src={video.posterUrl} width="100%" height="100%" preview={false} />
                        </div>
                        <div style={{ flex: 1, marginLeft: '12px', overflow: 'hidden' }}>
                          <Typography.Text type="secondary">
                            Publicado: {dayjs(video.date).format('LL')}
                          </Typography.Text>
                          <Typography.Paragraph>
                            {video.caption?.substring(0, 120) || 'Sin título disponible'}
                          </Typography.Paragraph>
                          <Typography.Link href={video.videoUrl} target="_blank">
                            {video.videoUrl}
                          </Typography.Link>
                        </div>
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => onSelect(video)}
                          style={{
                            position: 'absolute',
                            top: '8px',
                            right: '8px',
                            height: '22px',
                            fontSize: '10px',
                            padding: '0 8px',
                          }}
                        >
                          Seleccionar
                        </Button>
                      </div>
                    </List.Item>
                  )}
                />
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={filteredVideos.length}
                  onChange={setCurrentPage}
                  style={{ textAlign: 'center', marginTop: '16px' }}
                />
              </>
            ),
          },
          {
            key: 'instagram-reels',
            label: 'Reels de Instagram',
            children: (
              <>
  
                  <List
                    grid={{ gutter: 16, column: 2 }}
                    dataSource={reels.slice((currentReelsPage - 1) * reelsPageSize, currentReelsPage * reelsPageSize)}
                    loading={loadingReels} // ✅ Agregamos loading a la lista de reels
                    renderItem={reel => (
                      <List.Item>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '12px',
                            border: '1px solid #e8e8e8',
                            borderRadius: '8px',
                            position: 'relative',
                            height: '180px',
                          }}
                        >
                          <div style={{ width: '120px', height: '100%' }}>
                            <Image src={reel.displayUrl} width="100%" height="100%" preview={false} />
                          </div>
                          <div style={{ flex: 1, marginLeft: '12px', overflow: 'hidden' }}>
                            <Typography.Text type="secondary">
                              Publicado: {dayjs(reel.timestamp).format('LL')}
                            </Typography.Text>
                            <Typography.Paragraph>
                              {reel.caption.substring(0, 120) || 'Sin título disponible'}
                            </Typography.Paragraph>
                            <Typography.Link href={reel.url} target="_blank">
                              {reel.url}
                            </Typography.Link>
                          </div>
                          <Button
                            type="primary"
                            size="small"
                            onClick={() => onSelect(reel)}
                            style={{
                              position: 'absolute',
                              top: '8px',
                              right: '8px',
                              height: '22px',
                              fontSize: '10px',
                              padding: '0 8px',
                            }}
                          >
                            Seleccionar
                          </Button>
                        </div>
                      </List.Item>
                    )}
                  />
                
                <Pagination
                  current={currentReelsPage}
                  pageSize={reelsPageSize}
                  total={reels.length}
                  onChange={setCurrentReelsPage}
                  style={{ textAlign: 'center', marginTop: '16px' }}
                />
              </>
            ),
          },
        ]}
      />
    </Modal>
  );
};
